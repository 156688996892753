/* Common Imports */

import { Theme } from "@mui/material/styles";
import { styled } from "@mui/system";
import React from "react";
import Layout from "../../components/layout-components/Layout";

/* Authentication imports */

/* Markdown Imports */

import Home from "../../components/page-sections/docs/home.mdx";
import UnauthorisedInternal from "../../components/page-sections/docs/unauthorised-internal.mdx";
import Unauthorised from "../../components/page-sections/docs/unauthorised.mdx";
import authenticationTokenGenerator from "../../lib/authentication_token_generator";
import StateInterface from "../../redux-magic/state-interface";
import { wrapper } from "../../redux-magic/store";
import { bottom_subscribe_buy_beegru_points } from "../../redux-magic/sub-state-data-empty/bottom-subscribe-buy-beegru-points";
import { profile_context } from "../../redux-magic/sub-state-data/profile-context";

/* First, let's fetch a list of allowed usernames from the database and check if the current user matches one of the entries using getServerSideProps() */

// export const getServerSideProps = async (ctx: any) => {
//   const allowedUsers = (
//     await (await fetch(process.env.PRODUCTION_API_URL + "allowed-users")).json()
//   ).map((user: { username: string; phone: string }) => {
//     return user.phone;
//   });

//   const session = await getSession(ctx);

//   return session
//     ? {
//         props: {
//           authorised:
//             session && session.authorized
//               ? allowedUsers.includes(session.user.phone)
//               : false,
//           unauthorised:
//             session && session.authorized ? true : false,
//           user:
//             session && session.authorized
//               ? session.user
//               : undefined,
//         },
//       }
//     : {
//         props: {
//           authorised: false,
//           unauthorised: false,
//           user: false,
//         },
//       };
// };

export const getServerSideProps = wrapper.getServerSideProps((store: any) => async (context: any) => {
	const session: any = await authenticationTokenGenerator(context);
	return {
		props: {
			session: JSON.parse(JSON.stringify(session)),
		},
	};
});
/* Before we begin, let's define a custom attribute for our container called index. This will help the layout container organise our component correctly. */

type CustomAttributesForSpanElement = {
	index?: number;
};

/* Let's define a styled span and button */

const Span = styled("span")<CustomAttributesForSpanElement>(({ theme }) => ({}));

const Docs = ({
	authorised,
	unauthorised,
	user,
	session,
	theme,
	showInactivityDialog,
	dispatch,
	new_listing_url,
	bottom_buy_subscription,
}: {
	authorised: boolean;
	unauthorised: boolean;
	session: any;
	theme: Theme;
	showInactivityDialog: boolean;
	dispatch: Function;
	user:
		| {
				authenticationMode: boolean;
				authorized: boolean;
				date: Date;
				ipAddress: string;
				phone: string;
				validOTP: boolean;
				validPhoneNumber: string;
		  }
		| undefined;
	new_listing_url: StateInterface["new_listing_url"];
	bottom_buy_subscription: StateInterface["bottom_subscribe_buy_beegru_points"];
}) => {
	/* Let's pass some props to the MDX files */

	React.createElement(Home, {
		user: user
			? user
			: {
					authenticationMode: "sendOTP",
					authorized: false,
					date: new Date(),
					ipAddress: "unknown",
					phone: "unknown",
					validOTP: false,
					validPhoneNumber: false,
				},
	});
	React.createElement(UnauthorisedInternal, {
		user: user
			? user
			: {
					authenticationMode: "sendOTP",
					authorized: false,
					date: new Date(),
					ipAddress: "unknown",
					phone: "unknown",
					validOTP: false,
					validPhoneNumber: false,
				},
	});

	if (authorised) {
		return (
			<React.Fragment>
				<Layout
					noLayoutHeader={true}
					noLayoutFooter={true}
					session={session}
					showInactivityDialog={showInactivityDialog}
					theme={theme}
					dispatch={dispatch}
					profile_context={profile_context}
					new_listing_url={new_listing_url ? new_listing_url : ""}
					bottom_buy_subscription={
						bottom_buy_subscription ? bottom_buy_subscription : bottom_subscribe_buy_beegru_points
					}
				>
					<Span index={0}>
						<Home
							user={
								user
									? user
									: {
											authenticationMode: "sendOTP",
											authorized: false,
											date: new Date(),
											ipAddress: "unknown",
											phone: "unknown",
											validOTP: false,
											validPhoneNumber: false,
										}
							}
						/>
					</Span>
				</Layout>
			</React.Fragment>
		);
	} else if (unauthorised) {
		return (
			<React.Fragment>
				<Layout
					noLayoutHeader={true}
					noLayoutFooter={true}
					session={session}
					showInactivityDialog={showInactivityDialog}
					theme={theme}
					dispatch={dispatch}
					profile_context={profile_context}
					new_listing_url={new_listing_url ? new_listing_url : ""}
					bottom_buy_subscription={
						bottom_buy_subscription ? bottom_buy_subscription : bottom_subscribe_buy_beegru_points
					}
				>
					<Span index={0}>
						<UnauthorisedInternal
							user={
								user
									? user
									: {
											authenticationMode: "sendOTP",
											authorized: false,
											date: new Date(),
											ipAddress: "unknown",
											phone: "unknown",
											validOTP: false,
											validPhoneNumber: false,
										}
							}
						/>
					</Span>
				</Layout>
			</React.Fragment>
		);
	} else {
		return (
			<React.Fragment>
				<Layout
					noLayoutHeader={true}
					noLayoutFooter={true}
					session={session}
					showInactivityDialog={showInactivityDialog}
					theme={theme}
					dispatch={dispatch}
					profile_context={profile_context}
					new_listing_url={new_listing_url ? new_listing_url : ""}
					bottom_buy_subscription={
						bottom_buy_subscription ? bottom_buy_subscription : bottom_subscribe_buy_beegru_points
					}
				>
					<Span index={0}>
						<Unauthorised />
					</Span>
				</Layout>
			</React.Fragment>
		);
	}
};

export default Docs;

// import React from "react";

// const Index = () => {
//     return <React.Fragment>
//         <span>
//             Hello world!
//         </span>
//     </React.Fragment>;
// };

// export default Index;
